// General
import { useTranslation } from 'gatsby-plugin-react-i18next';
// Hooks
import { useSelector } from 'react-redux';

// Ui-kit
import AlertIcon from 'ui-kit/icons/alert/alert-icon';

// Components
import { AutoRefillToggle } from 'components/auto-refill-toggle';

// States
import { easyRefillBusySelector } from 'state/easy-refill/easy-refill.selectors';

// Interfaces and Types
import { PrescriptionObjectPayload } from 'types/prescription';

// Styles
import './auto-refill-disclaimer.style.scss';

export interface EasyAutoRefillDisclaimerProps {
    prescription?: PrescriptionObjectPayload;
    autoRefillEligible: boolean;
    autoRefillEnabled: boolean;
    onAutoRefillToggle: (rx: PrescriptionObjectPayload, val: boolean) => void;
}

// Main component
export const AutoRefillDisclaimer: React.FC<EasyAutoRefillDisclaimerProps> = ({
    prescription,
    autoRefillEligible,
    autoRefillEnabled,
    onAutoRefillToggle
}) => {
    const { t } = useTranslation();
    const autoRefillToggleBusy = useSelector(easyRefillBusySelector);

    if (!prescription) return <></>;

    return (
        <div className="auto-refill-disclaimer-container text-center">
            <div className="auto-refill-disclaimer-icon-container">
                <AlertIcon />
                <span>{t('pages.cartOrderConfirmation.autoRefill.turnOnAutoRefillQuestion')}</span>
            </div>
            <div className="auto-refill-disclaimer">
                <p>{t('pages.cartOrderConfirmation.autoRefill.yourRxIsElegible')}</p>
                <p>{t('pages.cartOrderConfirmation.autoRefill.turnItOnMessage')}</p>

                <AutoRefillToggle
                    checked={autoRefillEnabled}
                    onChange={(val: boolean) => onAutoRefillToggle(prescription, val)}
                    t={t}
                    rxEligible={autoRefillEligible}
                    isBusy={autoRefillToggleBusy}
                />
            </div>
        </div>
    );
};
