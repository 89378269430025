import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { formatPrice } from 'schema/price.schema';

import useCart from 'hooks/useCart';

export const CartOrderedTotal = () => {
    const { t } = useTranslation();
    const { cartData } = useCart();
    return (
        <Container fluid className="cart-total d-flex flex-column">
            <Row>
                <Col>
                    <div className="cart-total--subTotal d-flex justify-content-end">
                        <h3 className="cart-total--title mt-4">
                            {t('pages.cart.subTotal')}
                            <span className="cart-total--price-container">{formatPrice(cartData?.subTotal)}</span>
                        </h3>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--shipping d-flex justify-content-end">
                        <h3 className="cart-total--title">
                            {t('pages.cart.shippingTotal')}
                            <span className="cart-total--price-container">
                                {formatPrice(cartData?.shipMethodPrice)}
                            </span>
                        </h3>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--total d-flex justify-content-end mt-3">
                        <h3 className="cart-total--title font-weight-bold mt-3">
                            {t('pages.cart.estTotal')}
                            <span className="cart-total--price-container">{formatPrice(cartData?.total)}</span>
                        </h3>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
