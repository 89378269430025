// General
import { graphql, navigate } from 'gatsby';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// Ui-kit
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import Link from 'ui-kit/link/link';
import PageSection from 'ui-kit/page-section/page-section';
import ToastBox from 'ui-kit/toast-box/toast-box';

import TermsAndConditionsContent from 'display-components/auto-refill-modal-contents/terms-and-conditions';

// Components
import AutoRefillBanner from 'components/auto-refill-banner/auto-refill-banner.component';
import { CartOrderedTotal } from 'components/easy-refill/cart-ordered-total.component';
import { PrescriptionInformation } from 'components/easy-refill/review/prescription-information.component';
import InternalHeader from 'components/internal-header/internal-header.component';
import MarketingpageLayout from 'components/layouts/page/marketingpage.layout';
import { PrescriptionContentSectionHeader } from 'components/prescription-content-section-header';

// State
import {
    autoRefillFlagsSelector,
    easyRefillAccountIsCaliforniaUserSelector,
    easyRefillAutoRefillStatusSelector,
    easyRefillFamilyDependentsSelector,
    easyRefillOrderNumberSelector,
    easyRefillUserBearerTokenSelector
} from 'state/easy-refill/easy-refill.selectors';

import { PrescriptionObjectPayload } from 'types/prescription';

// Util
import { DEFAULT_SHIPPING_ID } from 'util/cart';
import storageHelper from 'util/storageHelper';
import { replaceStringWith } from 'util/string';

import { useAutoRefillToggle } from 'hooks/useAutoRefillToggle';
// Hooks
import usePopState from 'hooks/usePopState';
import usePrescriptionCards from 'hooks/usePrescriptionCards';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

// HOC
import withUnauthenticatedSessionExpiration from 'hoc/withUnauthenticatedSessionExpiration';

// Styles
import './index.style.scss';

// Main component
const EasyRefillOrderConfirmation = ({ data }: { data: GatsbyTypes.EasyRefillOrderConfirmationDataQuery }) => {
    // General
    const { blueSkyBackground } = data;

    // Hooks
    const { t } = useTranslation();
    const { mainUserIsCaregiver, lastOrder } = usePrescriptionFlow();
    const { handleToggleAutoRefill, rxWithAutoRefill } = useAutoRefillToggle();
    const { prescriptionCards } = usePrescriptionCards();

    // Hooks: states

    // Selectors: before Caregivers implementation
    const orderNumber = useSelector(easyRefillOrderNumberSelector);
    const easyRefillBearerToken = useSelector(easyRefillUserBearerTokenSelector);
    const autorefillFlags = useSelector(autoRefillFlagsSelector);
    const isCaliforniaUser = useSelector(easyRefillAccountIsCaliforniaUserSelector);
    const easyRefillFamilyDependents = useSelector(easyRefillFamilyDependentsSelector);
    const easyRefillAutoRefill = useSelector(easyRefillAutoRefillStatusSelector);

    const orderShippingAddress = useMemo(() => lastOrder?.address, [lastOrder]);

    // The autorefill banner should be hidden if one of these conditions applies:
    const shouldDisplayAutoRefillInfo = useMemo(() => {
        // 1. Check if the patient's primary plan doesn't allow auto-refill.
        const isPlanIneligible = autorefillFlags?.some((item) => item.planAutoRefillEligible === false);

        if (isPlanIneligible || isCaliforniaUser || easyRefillAutoRefill) {
            return false;
        }

        if (mainUserIsCaregiver) {
            return easyRefillFamilyDependents.some((dependent) =>
                dependent.rxResults.some((prescription) => prescription.autoRefillEligible)
            );
        } else {
            return autorefillFlags?.some((item) => item.autoRefillEligible);
        }
    }, [autorefillFlags, easyRefillAutoRefill, easyRefillFamilyDependents, mainUserIsCaregiver, isCaliforniaUser]);

    // DRX-3165: This usePopState marks an order placement by setting a cookie. Since we handle family members,
    // it's possible for the user to navigate back and add more refills for their family members. so we ensure
    // that when the user goes back it would be redirected to the prescriptions page.
    // More info here: https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event
    usePopState(() => {
        storageHelper.cookies.setHasOrderEasyRefillPlaced();
        navigate('/easy-refill/prescriptions', { replace: true });
    });

    // Handle Auto refill toggle click
    const onChangeAutoRefill = useCallback(
        (prescription: PrescriptionObjectPayload, autoRefillEnabled: boolean) => {
            // rxNumber: string, rxSeqNumber: string, autoRefillEnabled: boolean, isRenew?: boolean
            const hasAnyRxAutorefillOn = prescriptionCards?.some((rx) => rx.autoRefillEnabled);
            handleToggleAutoRefill(
                { rxNumber: prescription.rxNumber, rxSeqNum: prescription.rxSeqNum },
                autoRefillEnabled,
                hasAnyRxAutorefillOn,
                <TermsAndConditionsContent />,
                false
            );
        },
        [handleToggleAutoRefill, prescriptionCards]
    );

    useEffect(() => {
        if (!easyRefillBearerToken) {
            navigate('/link-expired?flow=easy-refill');
        }
    }, [easyRefillBearerToken]);

    return (
        <>
            <MarketingpageLayout
                headerImage={blueSkyBackground}
                headerImageClassName="profile-background"
                metaData={{ nodeTitle: 'Easy Refill' }}
                suppressApplicationPage={true}
            >
                <PageSection>
                    <Container fluid className={`easy-refill-order-confirmation`}>
                        <CartIcon className={'easy-refill-order-confirmation-icon-header'} />
                        <InternalHeader
                            title={t('pages.easyRefill.orderConfirmation.headlineText')}
                            eyebrowText={t('pages.easyRefill.orderConfirmation.eyebrowText')}
                            sectionIndex={0}
                            columnWidthOnDesktop={10}
                            variant="blue-background"
                            showSpacer
                        />
                        <Row>
                            <Col xs={12} md={12} lg={9} className="easy-refill-order-confirmation-main-container">
                                <Row className="easy-refill-order-confirmation-order-message">
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <h4
                                            dangerouslySetInnerHTML={{
                                                __html: t('pages.easyRefill.orderConfirmation.orderMessage', {
                                                    orderNumber: orderNumber
                                                })
                                            }}
                                        ></h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <PrescriptionContentSectionHeader
                                            label={t(
                                                'pages.easyRefill.orderConfirmation.sectionHeaders.prescriptionInformation'
                                            )}
                                            pricingLabel={t('pages.cart.pricing')}
                                            className="easy-refill-layout-content-section-header"
                                        />
                                        <PrescriptionInformation
                                            rxWithAutoRefill={rxWithAutoRefill}
                                            onPrescriptionAutoRefillChange={onChangeAutoRefill}
                                            isOrderCompleted={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <CartOrderedTotal />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <PrescriptionContentSectionHeader
                                            label={t(
                                                'pages.easyRefill.orderConfirmation.sectionHeaders.shippingInformation'
                                            )}
                                            className="easy-refill-layout-content-section-header"
                                        />
                                        <Row>
                                            <Col className="easy-refill-order-confirmation-section-content p-0 mt-1">
                                                <div className="easy-refill-order-confirmation-default-address-label">
                                                    <span>
                                                        {t(
                                                            'pages.easyRefill.orderConfirmation.shippingInformation.defaultAddressLabel'
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="easy-refill-order-confirmation-default-address">
                                                    {orderShippingAddress && (
                                                        <>
                                                            <span className="easy-refill-order-confirmation-section-sentence">
                                                                {orderShippingAddress.address1}{' '}
                                                                {orderShippingAddress.address2
                                                                    ? orderShippingAddress.address2
                                                                    : ''}
                                                            </span>
                                                            <span className="easy-refill-order-confirmation-section-sentence">
                                                                {orderShippingAddress.city},{' '}
                                                                {orderShippingAddress.state}{' '}
                                                                {orderShippingAddress.zipcodeFour
                                                                    ? `${orderShippingAddress.zipcode}-${orderShippingAddress.zipcodeFour}`
                                                                    : orderShippingAddress.zipcode}
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <PrescriptionContentSectionHeader
                                            label={t(
                                                'pages.easyRefill.orderConfirmation.sectionHeaders.shippingMethod'
                                            )}
                                            className="easy-refill-layout-content-section-header"
                                        />
                                        <Row>
                                            <Col className="mt-1 mb-3">
                                                <span>
                                                    {lastOrder?.shipMethodId === DEFAULT_SHIPPING_ID
                                                        ? t('pages.cart.freeShipping')
                                                        : t('pages.cart.expeditedShipping')}
                                                </span>
                                            </Col>
                                        </Row>
                                        <ToastBox icon="default" variant="info">
                                            {t('pages.cart.shippingFooter')}
                                        </ToastBox>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <PrescriptionContentSectionHeader
                                            label={t(
                                                'pages.easyRefill.orderConfirmation.sectionHeaders.paymentInformation'
                                            )}
                                            className="easy-refill-layout-content-section-header"
                                        />
                                        <Row>
                                            <Col className="easy-refill-order-confirmation-section-content p-0 mt-1">
                                                <div className="easy-refill-order-confirmation-default-payment-method-label">
                                                    <span>
                                                        {t(
                                                            'pages.easyRefill.orderConfirmation.paymentInformation.primaryPaymentMethod'
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="easy-refill-order-confirmation-default-payment-method">
                                                    {lastOrder?.paymentMethod && (
                                                        <>
                                                            <span>
                                                                {t(
                                                                    'pages.easyRefill.orderConfirmation.paymentInformation.cardEnding',
                                                                    {
                                                                        cardType: lastOrder?.paymentMethod.cardType,
                                                                        endingDigits: replaceStringWith(
                                                                            lastOrder?.paymentMethod.secureCardNumber,
                                                                            new RegExp(/\*/g),
                                                                            ''
                                                                        )
                                                                    }
                                                                )}
                                                                <br />
                                                                {lastOrder?.paymentMethod.cardName}
                                                                <br />
                                                                {lastOrder?.paymentMethod.cardExpiration}
                                                            </span>
                                                            <CreditCardIcon
                                                                className="payment-card__credit-card-icon"
                                                                variant={lastOrder?.paymentMethod.cardType}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* will be implemented on DRX-1765 */}
                                {ENABLE_AUTO_REFILL && shouldDisplayAutoRefillInfo && (
                                    <Row className="py-5">
                                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                            <AutoRefillBanner currentPage="order-confirmation" />
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                        <Row>
                                            <Col className="easy-refill-order-confirmation-section-content manage-prescriptions m-3">
                                                <span className="easy-refill-order-confirmation-section-sentence">
                                                    {t(
                                                        'pages.easyRefill.orderConfirmation.managePrescriptions.infoText'
                                                    )}
                                                </span>
                                                <Link
                                                    to={'/sign-in'}
                                                    label={t(
                                                        'pages.easyRefill.orderConfirmation.managePrescriptions.signIn'
                                                    )}
                                                    variant="cta-button"
                                                    dataGALocation="OrderConfirmation-Signin"
                                                    ariaLabel="OrderConfirmation-Signin"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </PageSection>
            </MarketingpageLayout>
        </>
    );
};

export default withUnauthenticatedSessionExpiration(EasyRefillOrderConfirmation, 'easy-refill');

export const query = graphql`
    query EasyRefillOrderConfirmationData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background-02.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
        allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
            nodes {
                field_physical_address
                field_mailing_address
                field_specialty_mailing_address
                field_specialty_physical_address
            }
        }
    }
`;
