// General
import { ENABLE_AUTO_REFILL, ENABLE_BIRDI_SELECT } from 'gatsby-env-variables';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

// Ui-kit
import Button from 'ui-kit/button/button';
import BirdiPriceIcon from 'ui-kit/icons/birdi-price/birdi-price-icon';
import PlanBadge from 'ui-kit/icons/plan-badge/plan-badge';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Components
import AutoRefillBadge from 'components/medicine-cabinet-cart/prescription-information/auto-refill-badge/auto-refill-badge';

import { formatPrice } from 'schema/price.schema';

import { CartOrderRx } from 'types/cart';
import { PrescriptionObjectPayload } from 'types/prescription';

// Interfaces and Types
// Util
import { getPhoneNumber } from 'util/globalVariables';

import useCart from 'hooks/useCart';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

import { AutoRefillDisclaimer } from '../auto-refill-disclaimer.component';
// Styles
import './prescription-information.style.scss';

interface ReviewPrescriptionInformationProps {
    rxWithAutoRefill?: string[];
    isOrderCompleted?: boolean;
    onPrescriptionAutoRefillChange: (rx: PrescriptionObjectPayload, val: boolean) => void;
    onRemoveOrder?: (prescriptionToRemove: CartOrderRx) => void;
}

// Main component
export const PrescriptionInformation: React.FC<ReviewPrescriptionInformationProps> = ({
    rxWithAutoRefill = [],
    isOrderCompleted,
    onRemoveOrder,
    onPrescriptionAutoRefillChange
}) => {
    const { t } = useTranslation();
    const { cartData } = useCart();
    const { mainUserIsCaregiver } = usePrescriptionFlow();

    const formatBirdiSavings = useCallback(
        (item: CartOrderRx): string => {
            if (!item.cartPrice) return '';

            const hasDiscount =
                !!item.cartPrice?.discount && item.cartPrice?.discount > 0 && item.cartPrice.showStrikeThruPricing;

            if (hasDiscount) {
                return t('components.medicineCabinetCart.prescriptionInfo.save', {
                    save: formatPrice(item.cartPrice?.discount)
                });
            } else {
                return '';
            }
        },
        [t]
    );

    const handleRemoveOrder = useCallback(
        (prescriptionToRemove: CartOrderRx) => {
            if (typeof onRemoveOrder === 'function') {
                onRemoveOrder(prescriptionToRemove);
            }
        },
        [onRemoveOrder]
    );

    return (
        <>
            <Container className="cart-prescription">
                {cartData?.patients.map((patient) =>
                    patient.cartRxs.map((item) => {
                        // const currentPrescription: RxDetails = item.prescriptionDetail;
                        const rxDetails = item.rxDetails.prescriptionDetail;
                        /**
                         * Ticket DRX-2863:
                         *
                         * As we don't update the cart object inside the Order Confirmation page,
                         * after enabling the RX autorefill flag, we store the rxSeqNum inside an
                         * array and use it to check inside the map if the current prescription
                         * had its autorefill flag enabled by the user.
                         */
                        const autoRefillEnabled = item.autoRefillEnabled
                            ? true
                            : rxWithAutoRefill
                            ? rxWithAutoRefill?.includes(item.rxDetails.rxNumber)
                            : false;

                        const hasDiscount =
                            !!item.cartPrice?.discount &&
                            item.cartPrice?.discount > 0 &&
                            item.cartPrice.showStrikeThruPricing;
                        const showBirdiPriceIcon = item.cartPrice?.hasKnownPrice && item.cartPrice?.showBirdiCashPrice;
                        const showAutoRefillFlag = autoRefillEnabled && patient.planEligibleForAutoRefill;
                        const hasBirdiSelect = ENABLE_BIRDI_SELECT && item.isBirdiSelect;

                        return (
                            <Row key={rxDetails.rxNumber} className="cart-prescription--item">
                                <Col sm={12} className="cart-prescription--order-header">
                                    <div>
                                        {mainUserIsCaregiver && (
                                            <h3 className="cart-prescription--patient-name">
                                                {`${patient.firstName} ${patient.lastName}`}
                                            </h3>
                                        )}
                                    </div>
                                    <div>
                                        {mainUserIsCaregiver && isOrderCompleted && rxDetails?.orderInvoiceNumber && (
                                            <div className="cart-prescription--product-info--reference">
                                                <span>Reference: {`#${rxDetails?.orderInvoiceNumber}`}</span>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col xs={7} sm={6} md={8} className="d-flex align-items-stretch flex-column">
                                    <div className="cart-prescription--product-name">
                                        <h3 className="cart-prescription--header">
                                            <span>{rxDetails?.dispensedProductName}</span>
                                            {hasBirdiSelect && <PlanBadge variant="birdi-select-blue" />}
                                        </h3>
                                    </div>

                                    <div className="cart-prescription--product-info">
                                        <div className="cart-prescription--product-info--rx-number">
                                            Rx #{rxDetails.rxNumber}
                                        </div>
                                        {rxDetails?.fillQuantity && (
                                            <div className="cart-prescription--product-info--content">
                                                {t('pages.cart.quantity', {
                                                    fillQuantity: rxDetails?.fillQuantity
                                                })}
                                            </div>
                                        )}
                                        {(rxDetails?.dispensedProductStrength ||
                                            rxDetails?.dispensedProductUnitOfMeasure) && (
                                            <div className="cart-prescription--product-info--content">
                                                {rxDetails?.dispensedProductStrength}
                                                {rxDetails?.dispensedProductUnitOfMeasure}
                                            </div>
                                        )}
                                        {rxDetails?.dispensedProductDosageForm && (
                                            <div className="cart-prescription--product-info--content dosage">
                                                {rxDetails?.dispensedProductDosageForm}
                                            </div>
                                        )}
                                    </div>

                                    <div className="d-none d-md-flex align-items-baseline ">
                                        {autoRefillEnabled && patient.planEligibleForAutoRefill && (
                                            <div className="mt-2">
                                                <AutoRefillBadge
                                                    text={t(
                                                        'components.medicineCabinetCart.prescriptionInfo.autoRefill'
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col xs={5} sm={6} md={4} className="text-right text-lg-right">
                                    <div className="d-flex flex-column">
                                        {/* Pricing logic */}
                                        {item.cartPrice?.hasKnownPrice ? (
                                            <>
                                                {hasDiscount && (
                                                    <div className="cart-prescription--patient-price-line">
                                                        ${item.cartPrice.awpPrice}
                                                    </div>
                                                )}
                                                <div className="cart-prescription--patient-price-total">
                                                    {formatPrice(item.cartPrice.finalPrice)}
                                                </div>
                                                {/* Est.Copay - just for insurance patients */}
                                                {item.isPrimaryPlanInsurance && !item.cartPrice.showBirdiCashPrice && (
                                                    <div className="cart-prescription--patient-price-info">
                                                        {t('components.medicineCabinetCart.prescriptionInfo.copay')}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="prescription-item-no-price">
                                                {t('components.medicineCabinetCart.prescriptionInfo.noPrice')}
                                            </div>
                                        )}

                                        {showBirdiPriceIcon && (
                                            <div>
                                                {/* Birdi price */}
                                                <div className="cart-prescription--patient-price-info">
                                                    <BirdiPriceIcon className="cart-prescription--patient-price-info-icon" />
                                                    <div>
                                                        {t(
                                                            'components.medicineCabinetCart.prescriptionInfo.birdiPrice'
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Discount price */}
                                                <div className="cart-prescription--patient-price-saving">
                                                    {formatBirdiSavings(item)}
                                                </div>
                                            </div>
                                        )}
                                        {onRemoveOrder && (
                                            <div className="mt-lg-0 d-flex justify-content-end">
                                                <Button
                                                    type="button"
                                                    variant="text"
                                                    className="p-0 mb-3 left-n1 ml-lg-auto btn-remove text-capitalize btn-underline no-min-width"
                                                    label={t('pages.cart.remove')}
                                                    onClick={() => handleRemoveOrder(item)}
                                                    dataGAFormName="addMorePrescriptions"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col className="d-block d-md-none" xs={12}>
                                    <div className="d-flex align-items-baseline">
                                        {showAutoRefillFlag && (
                                            <div className="mt-2">
                                                <AutoRefillBadge
                                                    text={t(
                                                        'components.medicineCabinetCart.prescriptionInfo.autoRefill'
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                {!!item.disclaimerTranslationKey && (
                                    <div className="cart-prescription--price-disclaimer">
                                        <ToastBox icon="default" variant="info">
                                            <div>
                                                <Trans
                                                    i18nKey={item.disclaimerTranslationKey}
                                                    values={{ phoneNumber: getPhoneNumber({}) }}
                                                />
                                            </div>
                                        </ToastBox>
                                    </div>
                                )}

                                {ENABLE_AUTO_REFILL &&
                                    !autoRefillEnabled &&
                                    rxDetails?.autoRefillEligible &&
                                    patient.planEligibleForAutoRefill && (
                                        <Col className="mt-3">
                                            <AutoRefillDisclaimer
                                                autoRefillEligible={rxDetails?.autoRefillEligible}
                                                prescription={item.rxDetails.prescriptionDetail}
                                                autoRefillEnabled={autoRefillEnabled || false}
                                                onAutoRefillToggle={(rx, val) =>
                                                    onPrescriptionAutoRefillChange(rx, val)
                                                }
                                            ></AutoRefillDisclaimer>
                                        </Col>
                                    )}
                            </Row>
                        );
                    })
                )}
            </Container>
        </>
    );
};
